import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Box, Typography, Card, CardContent, Grid, Paper, IconButton, Collapse, ThemeProvider, createTheme, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Delete } from '@mui/icons-material';
import axios from 'axios';
import baseUrl from '../config';

// Material-UI Theme
const theme = createTheme({
  palette: {
    primary: { main: '#3f51b5' }, // Blue
    secondary: { main: '#1976d2' }, // Light Blue
    background: { default: '#f5f5f5' },
    text: { primary: '#333', secondary: '#777' },
  },
  typography: {
    h3: { fontWeight: 600 },
    h5: { fontWeight: 500 },
    h6: { fontWeight: 500 },
    body2: { fontWeight: 400 },
  },
});

const CustomizedCourseDragAndDrop = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [courses, setCourses] = useState([]);
  const [customizedChapters, setCustomizedChapters] = useState([]);
  const [openCourse, setOpenCourse] = useState(null);

  // Fetch all courses from the backend
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/sales/courses`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        });
        setCourses(response.data); // Set courses from API response
      } catch (error) {
        console.error('Error fetching courses:', error.response?.data || error.message);
      }
    };

    fetchCourses();
  }, []);

  const handleDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;

    if (source.droppableId !== destination.droppableId) {
      // Moving between courses and customized chapters
      const sourceCourseId = source.droppableId;
      const sourceCourse = courses.find((course) => course._id === sourceCourseId);
      const chapter = sourceCourse.chapters[source.index];

      const newSourceCourse = {
        ...sourceCourse,
        chapters: [
          ...sourceCourse.chapters.slice(0, source.index),
          ...sourceCourse.chapters.slice(source.index + 1),
        ],
      };

      setCourses(courses.map((course) => 
        course._id === sourceCourseId ? newSourceCourse : course
      ));

      setCustomizedChapters([
        ...customizedChapters,
        { ...chapter, courseId: sourceCourseId },
      ]);
    } else {
      // Reordering within the same droppable area
      if (source.droppableId === 'customized') {
        const chapter = customizedChapters[source.index];
        const originalCourse = courses.find((course) => course._id === chapter.courseId);
        const newOriginalCourse = {
          ...originalCourse,
          chapters: [...originalCourse.chapters, chapter],
        };

        setCourses(courses.map((course) =>
          course._id === originalCourse._id ? newOriginalCourse : course
        ));

        setCustomizedChapters(customizedChapters.filter((ch) => ch._id !== chapter._id));
      } else {
        const sourceCourseId = source.droppableId;
        const sourceCourse = courses.find((course) => course._id === sourceCourseId);
        const reorderedChapters = Array.from(sourceCourse.chapters);
        const [removed] = reorderedChapters.splice(source.index, 1);
        reorderedChapters.splice(destination.index, 0, removed);

        const newSourceCourse = { ...sourceCourse, chapters: reorderedChapters };

        setCourses(courses.map((course) =>
          course._id === sourceCourseId ? newSourceCourse : course
        ));
      }
    }
  };

  const handleRemoveChapter = (chapter) => {
    const newCustomizedChapters = customizedChapters.filter((ch) => ch._id !== chapter._id);
    const originalCourse = courses.find((course) => course._id === chapter.courseId);
    const newOriginalCourse = {
      ...originalCourse,
      chapters: [...originalCourse.chapters, chapter],
    };

    setCustomizedChapters(newCustomizedChapters);
    setCourses(courses.map((course) =>
      course._id === originalCourse._id ? newOriginalCourse : course
    ));
  };

  const handleToggleCourse = (courseId) => {
    setOpenCourse(openCourse === courseId ? null : courseId);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const selectedCourses = courses.map(course => ({
        courseId: course._id,
        selectedChapters: customizedChapters.filter(chapter => chapter.courseId === course._id).map(chapter => chapter._id)
      }));

      const response = await axios.post(`${baseUrl}api/sales/customized-course`, {
        email,
        name,
        selectedCourses,
        courseType: 'customized',  // Or whatever course type is selected
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
console.log(response);

      setSuccessMessage('Customized course saved successfully!');
      setCustomizedChapters([]); // Clear the selected chapters after saving
      setOpenPopup(false); // Close the popup after successful submission
    } catch (err) {
      console.log(err);

      setError('There was an error creating your customized course.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: '40px', backgroundColor: theme.palette.background.default, minHeight: '100vh' }}>
        <Typography variant="h4" align="center" sx={{ color: theme.palette.primary.main, fontWeight: 'bold', marginBottom: '40px' }}>
          Customize Your Course
        </Typography>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Grid container spacing={5} justifyContent="center">
            {/* Left Column: Available Courses */}
            <Grid item xs={12} sm={6} md={5} sx={{ backgroundColor: '#fff', borderRadius: 2, padding: '20px' }}>
              <Typography variant="h5" sx={{ color: theme.palette.secondary.main, fontWeight: 600, textAlign: 'center', marginBottom: '30px' }}>
                Available Courses
              </Typography>

              {courses.map((course) => (
                <Card key={course._id} sx={{ boxShadow: 3, borderRadius: 3, backgroundColor: '#fff', marginBottom: '30px' }}>
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                        textAlign: 'center',
                        cursor: 'pointer',
                        '&:hover': { color: theme.palette.secondary.main },
                      }}
                      onClick={() => handleToggleCourse(course._id)}
                    >
                      {course.title}
                    </Typography>

                    <Collapse in={openCourse === course._id}>
                      <Droppable droppableId={course._id} direction="vertical">
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            sx={{
                              backgroundColor: theme.palette.background.paper,
                              padding: 3,
                              borderRadius: 2,
                            }}
                          >
                            {course.chapters.map((chapter, index) => (
                              <Draggable key={chapter._id} draggableId={chapter._id} index={index}>
                                {(provided) => (
                                  <Paper
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                      backgroundColor: '#e0e0e0',
                                      padding: '10px',
                                      borderRadius: 2,
                                      marginBottom: '10px',
                                    }}
                                  >
                                    <Typography variant="body2" sx={{ color: '#333' }}>
                                      {chapter.title}
                                    </Typography>
                                  </Paper>
                                )}
                              </Draggable>
                            ))}
                          </Box>
                        )}
                      </Droppable>
                    </Collapse>
                  </CardContent>
                </Card>
              ))}
            </Grid>

            {/* Right Column: Customized Chapters */}
            <Grid item xs={12} sm={6} md={5} sx={{ backgroundColor: '#fff', borderRadius: 2, padding: '20px' }}>
              <Typography variant="h5" sx={{ color: theme.palette.secondary.main, fontWeight: 600, textAlign: 'center', marginBottom: '30px' }}>
                Customized Chapters
              </Typography>

              <Droppable droppableId="customized" direction="vertical">
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      padding: 3,
                      borderRadius: 2,
                    }}
                  >
                    {customizedChapters.map((chapter, index) => (
                      <Draggable key={chapter._id} draggableId={chapter._id} index={index}>
                        {(provided) => (
                          <Paper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              backgroundColor: '#e0e0e0',
                              padding: '10px',
                              borderRadius: 2,
                              marginBottom: '10px',
                            }}
                          >
                            <Typography variant="body2" sx={{ color: '#333' }}>
                              {chapter.title}
                              <IconButton
                                size="small"
                                sx={{ marginLeft: 1, color: theme.palette.error.main }}
                                onClick={() => handleRemoveChapter(chapter)}
                              >
                                <Delete />
                              </IconButton>
                            </Typography>
                          </Paper>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Grid>
          </Grid>
        </DragDropContext>

        {/* Submit Button */}
        <Box sx={{ textAlign: 'center', marginTop: '30px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenPopup(true)}
            sx={{ padding: '10px 20px', fontSize: '16px' }}
          >
            Save Customized Course
          </Button>
        </Box>

        {/* Popup for form submission */}
        <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
          <DialogTitle>Save Customized Course</DialogTitle>
          <DialogContent>
            <TextField
              label="Your Email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ marginBottom: '20px' }}
            />
            <TextField
              label="Your Name"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{ marginBottom: '20px' }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenPopup(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default CustomizedCourseDragAndDrop;
