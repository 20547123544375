
const baseUrl =
//   process.env === "PRODUCTION"


    //test server ------------
    // "http://localhost:4000/"
//    

    //live server -----------------------------------------------------------------------

"https://backend.oratrics.in/"
     
    //-------------------------------
export default baseUrl;
 