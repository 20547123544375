import React, { useState } from "react";
import { TextField, Button, MenuItem, Select, InputLabel, FormControl, Box, Typography, Grid, Card, CardMedia } from "@mui/material";
import axios from "axios";
import baseUrl from "../config";
import headerImage from '../Assets/40552.png';  // Import the image
import centerimg from '../Assets/2150319869.png';  // Import the image
import footer from '../Assets/image419.png';  // Import the image

const ApplyCoupon = () => {
  const [couponCode, setCouponCode] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseLevel, setCourseLevel] = useState("");
  const [country, setCountry] = useState("");
  const [parentName, setParentName] = useState(""); // Add field for parent name
  const [childName, setChildName] = useState(""); // Add field for child name
  const [customerEmail, setCustomerEmail] = useState(""); // Add field for customer email
  const [message, setMessage] = useState("");
  const [originalPrice, setOriginalPrice] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [couponDiscount, setCouponDiscount] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null);

  const courseOptions = [
    { name: "Public Speaking, Creative Writing, Leadership and Confidence Building", levels: ["beginner", "advanced"] },
    { name: "Personality Development", levels: ["basic", "premium", "flagship"] }
  ];

  const countries = ["USA", "Canada", "UK", "EU", "ANZ", "Singapore"];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(baseUrl + "api/demo/applyCoupon", {
        couponCode,
        courseName,
        courseLevel,
        country,
        parentName, // Send parentName
        childName, // Send childName
        customerEmail, // Send customerEmail
      });

      const { originalPrice, discountedPrice, couponDiscount, expirationDate } = response.data;
      setOriginalPrice(originalPrice);
      setDiscountedPrice(discountedPrice);
      setCouponDiscount(couponDiscount);
      setExpirationDate(expirationDate);
      setMessage("Coupon applied successfully!");
    } catch (error) {
      setMessage(error.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 4,
        bgcolor: '#fff',
        fontFamily: 'Arial, sans-serif',
        width: '100%',
      }}
    >
      {/* Header Image */}
      <CardMedia
        component="img"
        image={headerImage}  // Use the imported image here
        alt="Header Image"
        sx={{
          width: '100%',
          maxHeight: '300px',
          objectFit: 'cover',
          marginBottom: 3,
        }}
      />

      {/* Welcome Text */}
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          marginTop: 4,
          textAlign: 'center',
          fontSize: { xs: '2rem', sm: '3rem', md: '4rem' }, // Responsive font size
        }}
      >
        Apply Coupon
      </Typography>

      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          maxWidth: '1200px', // Limit the max width for larger screens
        }}
      >
        {/* Left Image */}
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            order: { xs: 2, sm: 1 }, // Stacks on small screens, keeps order on larger screens
          }}
        >
          <Card sx={{ width: '100%' }}>
            <CardMedia
              component="img"
              image={centerimg} // Replace with left-side image URL
              alt="Child Image"
              sx={{
                width: '100%',
                objectFit: 'cover',
              }}
            />
          </Card>
        </Grid>

        {/* Form */}
        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: '100%',
              maxWidth: '500px', // Limit width for large screens
              mx: 'auto', // Center the form horizontally
            }}
          >
                 <TextField
              label="Parent Name"
              fullWidth
              value={parentName}
              onChange={(e) => setParentName(e.target.value)}
              required
              sx={{ marginBottom: 2 }}
            />

            <TextField
              label="Child Name"
              fullWidth
              value={childName}
              onChange={(e) => setChildName(e.target.value)}
              required
              sx={{ marginBottom: 2 }}
            />

            <TextField
              label="Customer Email"
              fullWidth
              value={customerEmail}
              onChange={(e) => setCustomerEmail(e.target.value)}
              required
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Coupon Code"
              fullWidth
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              required
              sx={{ marginBottom: 2 }}
            />

           
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          {/* Course Name */}
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Course Name"
              fullWidth
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
              required
            >
              {courseOptions.map((course) => (
                <MenuItem key={course.name} value={course.name}>
                  {course.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Course Level */}
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Course Level"
              fullWidth
              value={courseLevel}
              onChange={(e) => setCourseLevel(e.target.value)}
              required
            >
              {courseOptions.find((course) => course.name === courseName)?.levels.map((level) => (
                <MenuItem key={level} value={level}>
                  {level}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Country */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth required>
              <InputLabel>Country</InputLabel>
              <Select
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                label="Country"
              >
                {countries.map((countryOption) => (
                  <MenuItem key={countryOption} value={countryOption}>
                    {countryOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

       

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                mt: 2,
                backgroundColor: '#8B0000',
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#a52a2a',
                },
              }}
            >
              Apply Coupon
            </Button>

            {message && (
              <Typography variant="body2" color="textSecondary" mt={2}>
                {message}
              </Typography>
            )}

            {/* {originalPrice !== null && discountedPrice !== null && (
              <Box mt={3} sx={{ backgroundColor: "#f5f5f5", padding: 2 }}>
                <Typography variant="h6">Applied Coupon:</Typography>
                <Typography>Original Price: ${originalPrice}</Typography>
                <Typography>Discounted Price: ${discountedPrice}</Typography>
                <Typography>Coupon Discount: {couponDiscount}%</Typography>
                <Typography>Expires: {expirationDate}</Typography>
              </Box>
            )} */}
          </Box>
        </Grid>
      </Grid>

      {/* Footer */}
      {originalPrice !== null && discountedPrice !== null && (
        <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          maxWidth: '1200px',
          height: '113px',
          bgcolor: 'transparent',
          borderRadius: 2,
          p: 2,
          marginTop: 3,
          boxShadow: 2,
          mx: 'auto', // Center horizontally
        }}
      >
        {/* Left Image */}
        <img
          src={footer}
          alt="Thank You"
          style={{ marginRight: 16, height: '80px', width: 'auto' }}
        />

        {/* Center Text */}
        <Typography
          variant="body1"
          sx={{
            flexGrow: 1,
            textAlign: 'center',
            fontWeight: 'bold',
            color: 'black',
            fontSize: { xs: '16px', sm: '20px', md: '22px' },
          }}
        >
          Special potential enrollment fees.
        </Typography>

        {/* Right Prize */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: '#8B0000',
            borderRadius: '10%',
            padding: '10px 20px',
            fontSize: { xs: '20px', sm: '22px', md: '24px' },
          }}
        >
{          discountedPrice
}        </Typography>
      </Box>)}
    </Box>
  );
};

export default ApplyCoupon;
