import React, { Suspense, lazy, useMemo } from "react";
import { CssBaseline, createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CouponDetails from "./components/GetCoupon";
import CreateCouponForm from "./pages/Couponcode";
import GetEmpCoupon from "./components/GetEmpCoupon";
import CouponForm from "./components/CouponForm";
import ApplyCoupon from "./components/ApplyCoupon";
import CustomizedCourseDragAndDrop from "./components/CustomizedCourseDragAndDrop";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
const BookDemo = lazy(() => import("./pages/BookDemo"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Layout = lazy(() => import("./components/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ProtectedRoute = lazy(() => import("./ProtectedRoute"));
const BookingManagement = lazy(() => import("./pages/BookingManagement"));

function App() {
  // Define the theme configuration
  const theme = useMemo(() =>
    createTheme({
      palette: {
        mode: 'light',
        primary: {
          main: '#1976d2',
        },
        secondary: {
          main: '#dc004e',
        },
        background: {
          default: '#f0f2f5',
          paper: '#ffffff',
        },
      },
      typography: {
        fontFamily: 'Roboto, Arial, sans-serif',
      },
    }), []
  );

  return (
    <ThemeProvider theme={theme}>
          <DndProvider backend={HTML5Backend}>

      <CssBaseline />
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/coupon/:couponCode" element={<CouponDetails />} /> {/* Public coupon route */}

              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<Layout />}>
                  <Route index element={<Dashboard />} />
                  <Route path="/book-class" element={<BookDemo />} />
                  <Route path="/view-bookings" element={<BookingManagement />} />
                  <Route path="/coupon" element={<CreateCouponForm />} /> 
                  <Route path="/checkcoupon" element={<GetEmpCoupon />} /> 
                  <Route path="/newcoupon" element={<CouponForm />} /> 
                  <Route path="/ApplyCoupon" element={<ApplyCoupon />} /> 
                  <Route path="/drag" element={<CustomizedCourseDragAndDrop />} /> 

                  
                  
                </Route>
              </Route>
            </Routes>
          </Suspense>
        </LocalizationProvider>
      </BrowserRouter>
      </DndProvider>

    </ThemeProvider>
  );
}

export default App;
