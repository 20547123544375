import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';
import baseUrl from '../config';
import oratricslogo from '../Assets/logowhite.png';

const CouponDetails = () => {
  const { couponCode } = useParams();
  const [coupon, setCoupon] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCouponDetails = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/demo/coupons/${couponCode}`);
        setCoupon(response.data);
        setError(null); // Reset error if coupon is found
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setError('Coupon not found or expired.');
        } else {
          setError('An error occurred while fetching the coupon details.');
        }
        setCoupon(null); // Reset coupon data if there's an error
      }
    };

    fetchCouponDetails();
  }, [couponCode]);

  // Check if the coupon is expired
  const isCouponExpired = coupon && new Date(coupon.expirationTime) < new Date();

  if (error) {
    return (
      <Container sx={{ py: 4 }}>
        <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>
          {error}
        </Typography>
      </Container>
    );
  }

  if (!coupon) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container sx={{ py: 4 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {/* Coupon Card */}
        <Paper
          elevation={3}
          sx={{
            width: 800,
            height: 400,
            display: 'flex',
            overflow: 'hidden',
            borderRadius: '12px',
            border: '2px dashed #00103D',
            position: 'relative',
          }}
        >
          {/* Check if coupon is expired or not found */}
          {isCouponExpired || error ? (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f8d7da', // Red background for error
                borderRadius: '12px',
              }}
            >
              <Typography variant="h6" sx={{ color: '#721c24', fontWeight: 'bold' }}>
                Coupon not found or expired.
              </Typography>
            </Box>
          ) : (
            <>
              {/* Left Section */}
              <Box
                sx={{
                  width: '25%',
                  backgroundColor: '#00103D',
                  color: 'white',
                  padding: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={oratricslogo} // Replace with your logo URL
                  alt="Oratrics Logo"
                  style={{
                    width: '300px',
                    marginBottom: '10px',
                    transform: 'rotate(-90deg)', // Rotate the logo to the left
                  }}
                />
              </Box>

              {/* Middle Section */}
              <Box
                sx={{
                  width: '50%',
                  padding: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                {/* Coupon Code */}
                <Box sx={{ textAlign: 'center' }}>
                  <Typography
                    variant="h4"
                    sx={{
                      color: '#781E3F',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      fontSize: '2.1rem', // Increase font size
                      textAlign: 'center', // Center the text
                      position: 'relative', // For positioning the stars
                      display: 'flex',
                      alignItems: 'center', // Align text and stars horizontally
                      justifyContent: 'center',
                    }}
                  >
                    <span style={{ marginRight: '1px ' }}>★</span>
                    Coupon Code
                    <span style={{ marginLeft: '1px' }}>★</span>
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{ marginTop: 1, fontWeight: 'bold', color: '#000' }}
                  >
                    {coupon.couponCode}
                  </Typography>
                </Box>

                {/* Details Section */}
                <Box
                  sx={{
                    border: '1px solid #00103D',
                    padding: 2,
                    borderRadius: 1,
                  }}
                >
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Course Type:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">{coupon.courseType}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: 2 }}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Expiration Time:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        {new Date(coupon.expirationTime).toLocaleString()}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              {/* Right Section with Price Tag */}
              <Box
                sx={{
                  width: '25%',
                  backgroundColor: 'white', // Set background to white
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                {/* Left Arrow Part */}
                <Box
                  sx={{
                    width: 0,
                    height: 0,
                    borderTop: '30px solid transparent', // Top side of the arrow
                    borderBottom: '30px solid transparent', // Bottom side of the arrow
                    borderRight: '30px solid #00103D', // Left-facing arrow
                    position: 'absolute',
                    left: -5, // Position the arrow to the left of the price tag
                    bottom: 50, // Position the tag at the bottom of the container
                  }}
                />

                {/* Price Tag inside the flat area */}
                <Box
                  sx={{
                    backgroundColor: '#00103D',
                    color: 'white',
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    padding: '10px 0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '50px', // Adjust the height of the price tag
                    width: '150px', // Adjust width to match the arrow and tag size
                    position: 'absolute', // Make it absolute to position it within the container
                    bottom: 55, // Position the tag at the bottom of the container
                  }}
                >
                  ${coupon.discountAmount}
                </Box>
              </Box>
            </>
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default CouponDetails;
